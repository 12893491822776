export const keys = Object.freeze({
  signIn: 'signIn',
  // authenticate: 'authenticate',
  deleteSelectedEmployees: "deleteSelectedEmployees",
  deleteEmployeeMaster: "deleteEmployeeMaster",
  saveEmployeeMaster: "saveEmployeeMaster",
  employeeMaster: "employeeMaster",
  getEditEmployeeMaster: 'getEditEmployeeMaster',

  userMaster: "userMaster",
  getVendorCode: "getVendorCode",
  crewMembers: "crewMembers",
  saveUserMaster: 'saveUserMaster',
  updateUserMaster: 'updateUserMaster',
  getEditUserMaster: 'getEditUserMaster',
  getEditUserByDivision: 'getEditUserByDivision',
  getUserByMapDivision: 'getUserByMapDivision',

  saveUserImage: 'saveUserImage',
  deleteUserImage: 'deleteUserImage',

  areaMaster: "areaMaster",
  cityListByArea: (city) => `cityListByDistrict/${city}`,
  saveAreaMaster: 'saveAreaMaster',
  updateAreaMaster: 'updateAreaMaster',
  deleteAreaMaster: 'deleteAreaMaster',
  getEditAreaMaster: 'getEditAreaMaster',

  allowanceMaster: "allowanceMaster",
  saveAllowanceMaster: 'saveAllowanceMaster',
  updateAllowanceMaster: 'updateAllowanceMaster',
  deleteAllowanceMaster: 'deleteAllowanceMaster',
  getEditAllowanceMaster: 'getEditAllowanceMaster',

  accommodationMaster: "accommodationMaster",
  saveAccommodationMaster: 'saveAccommodationMaster',
  updateAccommodationMaster: 'updateAccommodationMaster',
  deleteAccommodationMaster: 'deleteAccommodationMaster',
  getEditAccommodationMaster: 'getEditAccommodationMaster',

  bankMaster: "bankMaster",
  saveBankMaster: "saveBankMaster",
  updateBankMaster: "updateBankMaster",
  deleteBankMaster: "deleteBankMaster",
  getEditBankMaster: 'getEditBankMaster',

  brandMaster: "brandMaster",
  saveBrandMaster: 'saveBrandMaster',
  updateBrandMaster: 'updateBrandMaster',
  deleteBrandMaster: 'deleteBrandMaster',
  getEditBrandMaster: 'getEditBrandMaster',

  claimBackofficeList: "claimBackofficeList",
  claimExecutiveList: "claimExecutiveList",
  claimManagerList: "claimManagerList",
  claimManagerListMain: "claimManagerListMain",
  getClaimBackofficeList: "getClaimBackofficeList",
  approveClaimIfoods: "approveClaimIfoods",
  approveClaimBackoffice: "approveClaimBackoffice",
  verifyClaim: "verifyClaim",
  rejectClaimIfoods: "rejectClaimIfoods",
  saveClaimMaster: "saveClaimMaster",
  updateClaimMaster: "updateClaimMaster",
  claimReport: "claimReport",
  claimExpenseReport: "claimExpenseReport",
  expenseDetailsShow: "expenseDetailsShow",
  claimApprovedAmtUpdate: "claimApprovedAmtUpdate",

  companyMaster: "companyMaster",
  saveCompanyMaster: "saveCompanyMaster",
  updateCompanyMaster: "updateCompanyMaster",
  deleteCompanyMaster: "deleteCompanyMaster",
  getEditCompanyMaster: 'getEditCompanyMaster',

  competitorMaster: "competitorMaster",
  saveCompetitorMaster: "saveCompetitorMaster",
  updateCompetitorMaster: "updateCompetitorMaster",
  deleteCompetitorMaster: "deleteCompetitorMaster",
  getEditCompetitorMaster: 'getEditCompetitorMaster',
  
  competitorOffers: "competitorOffers",
  saveCompetitorOffers: "saveCompetitorOffers",
  updateCompetitorOffers: "updateCompetitorOffers",
  deleteCompetitorOffers: "deleteCompetitorOffers",
  getEditCompetitorOffers: 'getEditCompetitorOffers',

  cityMaster: "cityMaster",
  cityListByDistrict: (district) => `cityListByDistrict/${district}`,
  saveCityMaster: 'saveCityMaster',
  updateCityMaster: 'updateCityMaster',
  deleteCityMaster: 'deleteCityMaster',

  divisionMaster: "divisionMaster",
  saveDivisionMaster: "saveDivisionMaster",
  updateDivisionMaster: "updateDivisionMaster",
  deleteDivisionMaster: "deleteDivisionMaster",
  getEditDivisionMaster: 'getEditDivisionMaster',

  dropdownMaster: "dropdownMaster",
  saveDropdownMaster: "saveDropdownMaster",
  updateDropdownMaster: "updateDropdownMaster",
  deleteDropdownMaster: "deleteDropdownMaster",
  getEditDropdownMaster: 'getEditDropdownMaster',
  dropdownListByDropdown: 'dropdownListByDropdown',

  dropdownlistMaster: "dropdownlistMaster",
  saveDropdownlistMaster: "saveDropdownlistMaster",
  updateDropdownlistMaster: "updateDropdownlistMaster",
  deleteDropdownlistMaster: "deleteDropdownlistMaster",
  getEditDropdownlistMaster: 'getEditDropdownlistMaster',

  energypriceMaster: "energypriceMaster",
  saveEnergyPriceMaster: "saveEnergyPriceMaster",
  updateEnergyPriceMaster: "updateEnergyPriceMaster",
  deleteEnergyPriceMaster: "deleteEnergyPriceMaster",
  getEditEnergyPriceMaster: 'getEditEnergyPriceMaster',

  expenseCategory: 'expenseCategory',
  expenseType: 'expenseType',
  cityType: 'cityType',
  stayType: 'stayType',

  energytypeMaster: "energytypeMaster",
  saveEnergyTypeMaster: "saveEnergyTypeMaster",
  updateEnergyTypeMaster: "updateEnergyTypeMaster",
  deleteEnergyTypeMaster: "deleteEnergyTypeMaster",
  getEditEnergyTypeMaster: 'getEditEnergyTypeMaster',

  countryMaster: "countryMaster",
  saveCountryMaster: 'saveCountryMaster',
  updateCountryMaster: 'updateCountryMaster',
  deleteCountryMaster: 'deleteCountryMaster',

  stateMaster: "stateMaster",
  stateListByZone: (zone) => `stateListByZone/${zone}`,
  saveStateMaster: 'saveStateMaster',
  updateStateMaster: 'updateStateMaster',
  deleteStateMaster: 'deleteStateMaster',

  districtMaster: "districtMaster",
  districtListByState: (stateId) => `districtListByState/${stateId}`,
  districtListBySubzone: (subzone) => `districtListBySubzone/${subzone}`,
  saveDistrictMaster: 'saveDistrictMaster',
  updateDistrictMaster: 'updateDistrictMaster',
  deleteDistrictMaster: 'deleteDistrictMaster',

  categoryMaster: "categoryMaster",
  saveCategoryMaster: 'saveCategoryMaster',
  categoryListByBrand: 'categoryListByBrand',
  updateCategoryMaster: 'updateCategoryMaster',
  deleteCategoryMaster: 'deleteCategoryMaster',
  getEditCategoryMaster: 'getEditCategoryMaster',

  departmentMaster: "departmentMaster",
  departmentList: "departmentList",
  saveDepartmentMaster: 'saveDepartmentMaster',
  updateDepartmentMaster: 'updateDepartmentMaster',
  deleteDepartmentMaster: 'deleteDepartmentMaster',
  getEditDepartmentMaster: 'getEditDepartmentMaster',
  departmentListByDivision: (division) => `departmentList/${division}`,

  designationMaster: "designationMaster",
  designationList: "designationList",
  saveDesignationMaster: 'saveDesignationMaster',
  updateDesignationMaster: 'updateDesignationMaster',
  deleteDesignationMaster: 'deleteDesignationMaster',
  getEditDesignationMaster: 'getEditDesignationMaster',
  designationListByDepartment: (department) => `designationList/${department}`,


  outletMaster: "outletMaster",
  saveOutletMaster: 'saveOutletMaster',
  updateOutletMaster: 'updateOutletMaster',
  deleteOutletMaster: 'deleteOutletMaster',
  getEditOutletMaster: 'getEditOutletMaster',
  outletBySubzone: (subzone) => `outletBySubzone/${subzone}`,

  menuMaster: "menuMaster",
  deleteMenuMaster: 'deleteMenuMaster',

  materialMaster: "materialMaster",
  getMaterialDataFromSap: "getMaterialDataFromSap",
  saveMaterialMaster: "saveMaterialMaster",
  updateMaterialMaster: "updateMaterialMaster",
  materialDashboard: "materialDashboard",
  deleteMaterialMaster: "deleteMaterialMaster",
  getEditMaterialMaster: "getEditMaterialMaster",

  menuList: "menuList",
  menuListForAccess: "menuListForAccess",
  deleteMenuListMaster: 'deleteMenuListMaster',
   
  menuAccess: "menuAccess",
  getEditMenuAccess: "getEditMenuAccess",
  saveMenuAccess: "saveMenuAccess",
  updateMenuAccess: "updateMenuAccess",
  deleteMenuAccess: "deleteMenuAccess",

  plantMaster: "plantMaster",
  plantList: "plantList",
  savePlantMaster: 'savePlantMaster',
  updatePlantMaster: 'updatePlantMaster',
  deletePlantMaster: 'deletePlantMaster',
  getEditPlantMaster: 'getEditPlantMaster',
  plantListByDivision: (division) => `plantList/${division}`,

  productMaster: "productMaster",
  productListByCategory: 'productListByCategory',
  saveProductMaster: 'saveProductMaster',
  updateProductMaster: 'updateProductMaster',
  deleteProductMaster: 'deleteProductMaster',
  getEditProductMaster: 'getEditProductMaster',

  prospectMaster: "prospectMaster",
  saveProspectMaster: 'saveProspectMaster',
  prospectApproval: 'prospectApproval',
  updateProspectMaster: 'updateProspectMaster',
  pendingProspectMaster: 'pendingProspectMaster',
  deleteProspectMaster: 'deleteProspectMaster',
  getEditProspectMaster: 'getEditProspectMaster',
  prospectReport: 'prospectReport',

  prospectValidation: 'prospectValidation',
  getEditProspectApprove: 'getEditProspectApprove',

  shiftMaster: "shiftMaster",
  saveShiftMaster: 'saveShiftMaster',
  updateShiftMaster: 'updateShiftMaster',
  deleteShiftMaster: 'deleteShiftMaster',
  getEditShiftMaster: 'getEditShiftMaster',

  gradeMaster: "gradeMaster",
  gradeMasterOption: "gradeMasterOption",
  saveGradeMaster: 'saveGradeMaster',
  updateGradeMaster: 'updateGradeMaster',
  deleteGradeMaster: 'deleteGradeMaster',
  getEditGradeMaster: 'getEditGradeMaster',

  gradeList: "gradeList",
  gradeListOption: "gradeListOption",
  saveGradeList: 'saveGradeList',
  updateGradeList: 'updateGradeList',
  deleteGradeList: 'deleteGradeList',
  getEditGradeList: 'getEditGradeList',

  zoneMaster: "zoneMaster",
  accessibleZoneMaster: "accessibleZoneMaster",
  zoneByDivision: "zoneByDivision",
  saveZoneMaster: 'saveZoneMaster',
  updateZoneMaster: 'updateZoneMaster',
  deleteZoneMaster: 'deleteZoneMaster',

  subzoneMaster: "subzoneMaster",
  savesubzoneMaster: 'savesubzoneMaster',
  updatesubzoneMaster: 'updatesubzoneMaster',
  deletesubzoneMaster: 'deletesubzoneMaster',

  roleMaster: "roleMaster",
  saveRoleMaster: 'saveRoleMaster',
  updateRoleMaster: 'updateRoleMaster',
  deleteRoleMaster : 'deleteRoleMaster ',

  venueMaster: "venueMaster",
  saveVenueMaster: "saveVenueMaster",
  updateVenueMaster: "updateVenueMaster",
  getEditVenueMaster: 'getEditVenueMaster',

  pjpStore: 'pjpStore',
  pjpUpdate: 'pjpUpdate',
  pjpDelete: 'pjpDelete',
  pjpMaster: 'pjpMaster',
  getEditPjpMaster: 'getEditPjpMaster',

  primaryCustomer: "primaryCustomer",
  getPrimeCustDataFromSap: "getPrimeCustDataFromSap",
  primeCustListBySubzone: (subzone) => `primeCustListBySubzone/${subzone}`,
  primeCustListByCity: (city) => `primeCustListByCity/${city}`,
  savePrimaryCustomerMaster : 'savePrimaryCustomerMaster',
  updatePrimaryCustomerMaster : 'updatePrimaryCustomerMaster',
  deletePrimaryCustomerMaster : 'deletePrimaryCustomerMaster',
  getEditPrimaryCustomerMaster : 'getEditPrimaryCustomerMaster',
  primaryCustomerReport : 'primaryCustomerReport',

  primaryCustomerVisitReport : 'primaryCustomerVisitReport',
  secondaryCustomerVisitReport : 'secondaryCustomerVisitReport',
  secondaryOrderReport : 'secondaryOrderReport',

  secondaryCustomerMaster: "secondaryCustomerMaster",
  saveSecCustomerMaster: "saveSecCustomerMaster",
  updateSecCustomerMaster: "updateSecCustomerMaster",
  deleteSecondaryCustomerMaster: "deleteSecondaryCustomerMaster",
  getEditSecCustomerMaster: "getEditSecCustomerMaster",
  secondaryCustomerReport: "secondaryCustomerReport",

  getEditCountryMaster: 'getEditCountryMaster',
  getEditStateMaster: 'getEditStateMaster',
  getEditZoneMaster: 'getEditZoneMaster',
  getEditSubzoneMaster: 'getEditSubzoneMaster',
  getEditMenuMaster : 'getEditMenuMaster',
  getEditMenuListMaster : 'getEditMenuListMaster',
  getEditRoleMaster : 'getEditRoleMaster',

  ifoodsPlanApproval : 'ifoodsPlanApproval',
  getEditBoPlanApprove : 'getEditBoPlanApprove',
  approvePlan : 'approvePlan',
  rejectPlan : 'rejectPlan',

  getPlanApprManager : 'getPlanApprManager',
  approvePlanManager : 'approvePlanManager',
  rejectPlanManager : 'rejectPlanManager',
  getEditManagerPlanDevApprove : 'getEditManagerPlanDevApprove',

  getPlanDeviaApprManager : 'getPlanDeviaApprManager',
  deviationApprovalManager : 'deviationApprovalManager',
  deviationRejectManager : 'deviationApprovalManager',

  ifoodsPlanDevData : 'ifoodsPlanDevData',
  getEditBoPlanDevApprove : 'getEditBoPlanDevApprove',
  ifoodsPlanDevApprove : 'ifoodsPlanDevApprove',
  ifoodsPlanDevReject : 'ifoodsPlanDevReject',

  planApplicable : 'planApplicable',
  planCategoryListByDivision : 'planCategoryListByDivision',
  savePlanApplicable : 'savePlanApplicable',
  updatePlanApplicable : 'updatePlanApplicable',
  deletePlanApplicable : 'deletePlanApplicable',
  getEditPlanApplicable : 'getEditPlanApplicable',

  attendanceReport : 'attendanceReport',
  travelReport : 'travelReport',
  travelClaimReport : 'travelClaimReport',
  // planReport : 'planReport',
  planDetailsReport : 'planDetailsReport',
  primaryOrderListReport : 'primaryOrderListReport',
  primaryOrderDetails : 'primaryOrderDetails',
  appTrackReport : 'appTrackReport',
  marketPromotionReport : 'marketPromotionReport',
  outletVisitReport : 'outletVisitReport',
  productOfferReport : 'productOfferReport',
  competitorReport : 'competitorReport',
  expApplicableAmount : 'expApplicableAmount',
  getSharingUserList : 'getSharingUserList',
  getAllowTravelKm : 'getAllowTravelKm',
  userMapReport : 'userMapReport',
  pjpReport : 'pjpReport',
  appTransportMode: 'appTransportMode',

  glMaster: "glMaster",
  saveGlMaster: "saveGlMaster",
  updateGlMaster: "updateGlMaster",
  deleteGlMaster: "deleteGlMaster",
  getEditGlMaster: 'getEditGlMaster',

  foodsCompetitorMaster: "foodsCompetitorMaster",
  saveFoodsCompetitorMaster: "saveFoodsCompetitorMaster",
  updateFoodsCompetitorMaster: "updateFoodsCompetitorMaster",
  deleteFoodsCompetitorMaster: "deleteFoodsCompetitorMaster",
  getEditFoodsCompetitorMaster: 'getEditFoodsCompetitorMaster',

  notificationSettingsList: "notificationSettingsList",
  saveNotificationSettingsList: "saveNotificationSettingsList",
  deleteNotificationSettingsList: "deleteNotificationSettingsList",
  updateNotificationSettingsList: "updateNotificationSettingsList",

  commonNotificationList: "commonNotificationList",
  saveCommonNotificationList: "saveCommonNotificationList",
  deleteCommonNotificationList: "deleteCommonNotificationList",
  updateCommonNotificationList: "updateCommonNotificationList",

  getPrimaryOrderCount: 'getPrimaryOrderCount',

  getWebNotificationCount: 'getWebNotificationCount',

  primeCustMappingData: 'primeCustMappingData',
  fetchPrimeCustMappingData: 'fetchPrimeCustMappingData',

  secondaryCustomerList: 'secondaryCustomerList',
  secondaryCustomerSinApprove: 'secondaryCustomerSinApprove',
  secondaryCustomerMulApprove: 'secondaryCustomerMulApprove',
  getEditSecondaryCustomer: 'getEditSecondaryCustomer',
  secondaryByDistrict: (city) => `secondaryByDistrict/${city}`,

  claimLocationCategory: "claimLocationCategory",
  saveClaimLocationCategory: "saveClaimLocationCategory",
  updateClaimLocationCategory: "updateClaimLocationCategory",
  deleteClaimLocationCategory: "deleteClaimLocationCategory",
  getEditClaimLocationCategory: 'getEditClaimLocationCategory',

  claimFrequencyList: "claimFrequencyList",
  saveClaimFrequency: "saveClaimFrequency",
  updateClaimFrequency: "updateClaimFrequency",
  deleteClaimFrequency: "deleteClaimFrequency",
  getEditClaimFrequency: 'getEditClaimFrequency',

 
});
